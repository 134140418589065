import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Terms from "../components/terms"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <Terms />
  </Layout>
)

export default TermsPage
